import { TextField, Stack, Button } from '@mui/material';
import { useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { EMAIL_SUBSCRIPTION_URL } from '@/data/constants/externalURL';

export const EmailSubscriptionField = () => {
	const [email, setEmail] = useState<string>('');
	const [error, setError] = useState<boolean>(false);
	const router = useNextRouter();
	const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
	const handleSubmit = () => {
		if (emailRegex.test(email)) {
			router.push(
				{
					pathname: EMAIL_SUBSCRIPTION_URL,
					query: { email },
				}
			);
			setError(false);
		} else {
			setError(true);
		}
	};
	return (
		<Stack direction={'row'} sx={{ marginTop: 1 }}>
			<TextField
				required
				type="email"
				fullWidth
				id="email"
				name="email"
				autoComplete="email"
				placeholder="Enter Email Address"
				error={error}
				helperText={error ? 'Please enter a valid email' : null}
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				sx={{ borderTopRightRadius: '0', borderBottomRightRadius: 0 }}
			/>
			<Button
				onClick={handleSubmit}
				type="submit"
				sx={{
					backgroundColor: 'button.blue',
					borderTopLeftRadius: '0',
					borderBottomLeftRadius: '0',
				}}
			>
				<ArrowForwardIosIcon sx={{ color: 'primary.contrastText', fontSize: 16 }} />
			</Button>
		</Stack>
	);
};
