import { Copyright } from '@/components/content/Footer/parts/Copyright';
import { ContentRecommendation } from '@/components/content/ContentRecommendation';
import { footerBottomSX } from '@/components/content/Footer/styles/bottom';
import { footerContainerSX } from '@/components/content/Footer/styles/container';
import { useFooter } from '@/data/Content/Footer';
import { ID } from '@/data/types/Basic';
import { Container, Stack, Grid, useMediaQuery, useTheme, Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useSettings } from '@/data/Settings';
import { getChildItem } from '@/utils/getChildItem';
import { footerNavSX } from '@/components/content/Footer/styles/nav';
import { emailSignUpSX } from '@/components/content/Footer/styles/emailSignUp';
import { FOOTER_COMPANY_LINKS, FOOTER_EMAIL_COPY, FOOTER_SOCIAL_LINKS, FOOTER_POLICY, HEADER_COPY, FOOTER_BRAND_STATEMENT } from '@/data/constants/marketing';
import { EmailSubscriptionField } from '@/components/content/Footer/parts/EmailSubscriptionField';
import { useLocalization } from '@/data/Localization';
import { useJJKUser } from '@/data/User';

type Props = {
	id: ID
	clickAction?: () => void;
};
export const Footer: FC<Props> = ({ id }) => {
	const { settings } = useSettings();
	const { contentItems, urlBBB } = useFooter(id);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const { contentItem: footerLinks } = getChildItem(contentItems, FOOTER_COMPANY_LINKS);
	const { contentItem: emailForm } = getChildItem(contentItems, FOOTER_EMAIL_COPY);
	const { contentItem: socialLinks } = getChildItem(contentItems, FOOTER_SOCIAL_LINKS);
	const { contentItem: policyInfo } = getChildItem(contentItems, FOOTER_POLICY);
	const { contentItem: headerCopy } = getChildItem(contentItems, HEADER_COPY);
	const { contentItem: purposeBrandStatement } = getChildItem(contentItems, FOOTER_BRAND_STATEMENT);
	const punchOutUser = useJJKUser();
	const isPunchOut = punchOutUser?.user?.isPunchoutUser;
	return (
		<Box
			component="footer"
			mt={4}
			sx={{ width: '100%', backgroundColor: 'background.paper', borderTop: { xs: 'none', md: '1px solid #ddd' } }}

			{...(settings?.csrSession && { 'data-iframe-height': '' })}

		>
			{!isPunchOut ?
				(<>
					<Container maxWidth='xl' sx={footerContainerSX(settings?.csrSession)}>
						<Grid spacing={isMobile ? 3 : 0} container pt={isMobile ? 0 : '36px'}>
							<Grid item xs={12} md={9} sx={{ paddingRight: { md: '2.5rem' } }}>
								<Box sx={footerNavSX}>
									{footerLinks ?
										<ContentRecommendation
											id={`${id}-${footerLinks.emsName}`}
											properties={footerLinks}
										/> : null
									}
								</Box>
							</Grid>
							<Grid item xs={12} md={3} >
								<Grid container spacing={4} sx={{ maxWidth: { md: '95%' } }}>
									<Grid item xs={12} sm={6} md={12}>
										<Container sx={emailSignUpSX}>
											{emailForm ?
												<ContentRecommendation
													id={`${id}-${emailForm.emsName}`}
													properties={emailForm}
												/> : null
											}
											<EmailSubscriptionField />
										</Container>
									</Grid>
									<Grid item xs={12} sm={6} md={12} sx={{ 'width': '80%' }}>
										<Container sx={{ padding: { md: '0' } }}>
											{socialLinks ?
												<ContentRecommendation
													id={`${id}-${socialLinks.emsName}`}
													properties={socialLinks}
												/> : null
											}
										</Container>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Container>
					<Container maxWidth='xl'>
						<Stack pb={3.5} justifyContent={isMobile ? 'flex-start' : 'flex-end'} flexDirection={'row'}>
							<a href={urlBBB}>
								<img alt="Better Business Bureau logo" src="/images/BBB.svg" />
							</a>
						</Stack>
					</Container>
				</>
				) : null
			}

			<Box sx={footerBottomSX}>
				<Container maxWidth='xl'>
					<Box
						marginBottom={isMobile ? '1.0rem' : '2.0rem'}
						marginX={isMobile ? '2.0rem' : '0'}
						sx={{
							display: 'flex', flexDirection: 'row',
							alignItems: 'center', justifyContent: 'center',
						}}>


						<ContentRecommendation id={`${id}-${purposeBrandStatement?.emsName}`} properties={purposeBrandStatement} />


					</Box>
					<Stack justifyContent={isMobile ? 'center' : 'space-between'} spacing={isMobile ? 1.5 : 0} flexDirection={isMobile ? 'column' : 'row'}>
						<Box>
							{policyInfo ?
								<ContentRecommendation
									id={`${id}-${policyInfo.emsName}`}
									properties={policyInfo}
								/> : null
							}
						</Box>
						<Box>
							<Copyright isMobile={isMobile} />
						</Box>
					</Stack>
				</Container>
			</Box>
		</Box>
	);
};
